import { render, staticRenderFns } from "./ComissaoEntrega.vue?vue&type=template&id=ae00bf06&scoped=true&"
import script from "./ComissaoEntrega.vue?vue&type=script&lang=js&"
export * from "./ComissaoEntrega.vue?vue&type=script&lang=js&"
import style0 from "./ComissaoEntrega.vue?vue&type=style&index=0&id=ae00bf06&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ae00bf06",
  null
  
)

export default component.exports